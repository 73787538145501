import { IMAGES_ROUTES, INTERNAL_ROUTES } from "@data/constants/routes";
import { ColorSwitcher, ValueInspectionMEE } from "@data/interfaces";

export const GENERAL_CONSTANTS = {
    PATTERN:{
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    DIV: {
        AUTH_SHOW_HIDE_PASSWORD: 'show_hide_password',
        UPDATE_SHOW_HIDE_CURRENT_PASSWORD: 'current_show_hide_password',
        UPDATE_SHOW_HIDE_NEW_PASSWORD: 'new_show_hide_password',
        UPDATE_SHOW_HIDE_CONFIRM_NEW_PASSWORD: 'confirm_new_show_hide_password',
    },
    SELECT_OPTION_DEFAULT: 'Seleccione........',
    NO_RECORDS_FOUND: 'No se encontraron registros',
    NO_REGISTERED_INFORMATION: 'No existe información registrada',
    NOTIFICATION_TIME_4000_MS: 4000,
    NOTIFICATION_TIME_5000_MS: 5000,
    NOTIFICATION_TIME_6000_MS: 6000,
    PAGINATOR: {
        PAGE_SIZE: [5,10,20,50,100]
    },
    STATUS:{
        ACTIVE: 1,
        INACTIVE: 0,
        IS_ROOT: true,
        IS_NOT_ROOT: false,
        MSG_ACTIVE: 'Activo',
        MSG_INACTIVE: 'Inactivo',
        MSG_ACTIVATE: 'Activar',
        MSG_INACTIVATE: 'Inactivar',
    },
    TABLES: {
        TH_DOCUMENT: 'N° Documento',
        TH_TYPE_DOCUMENT: 'Tipo Documento',
        TH_FULL_NAME: 'Nombre Completo',
        TH_EMAIL: 'Correo Electrónico',
        TH_USERNAME: 'Usuario',
        TH_ACTIONS: 'Acciones',
        TH_STATUS: 'Estado',
        TH_CREATE_DATE: 'Fecha Creación',
        TH_ADDRESS: 'Dirección',
        TH_NIT: 'NIT',
        TH_LEGAL_NAME: 'Nombre Legal',
        TH_COMPANY: 'Empresa',
        MSG_GENERATE_PDF: 'Descargar PDF',
        MSG_UPDATE: 'Editar',
        MSG_RESET_PASSWORD: 'Reestablecer Contraseña',
        MSG_ASSIGN_ROL: 'Asignar Perfil',
        MSG_LIST_USERS: 'Consultar Usuarios',
        MSG_APPROVE: 'Aprobar',
        MSG_PARTIAL_APPROVE: 'Aprobado Parcial',
        MSG_DECLINE: 'Rechazar',
        MSG_INSTRUCTIVO: 'Diligenciar instructivo',
        MSG_PLUS: 'Gestionar Cronograma',
        MSG_UPDATE_STATUS: 'Inactivar',
    },
    SERVER: {
        SECONDS_HIDDEN: 7000,
        URL_USER_HOME: INTERNAL_ROUTES.PANEL_USER_HOME,
        UNAUTHORIZED_ACCESS_IMG: IMAGES_ROUTES.UNAUTHORIZED_ACCESS_IMG,
        UNAUTHORIZED_ACCESS: {
            PAGE_NOT_FOUND: 'Página No Encontrada',
            PAGE_NOT_FOUND_DESC: 'Lo sentimos, pero la página que buscas no existe.',
            PAGE_UNAUTHORIZED: '¡¡¡Acceso No Autorizado!!!',
            PAGE_UNAUTHORIZED_DESC: 'Lo sentimos, actualmente no tiene acceso o la página que busca no existe. Comuníquese con el administrador de la aplicación.',
            USER_UNASSIGNED_A_COMPANY_DESC: 'Lo sentimos, actualmente no pertenece a ninguna compañia o la misma se encuentra INACTIVA. Comuníquese con el administrador de la aplicación.',
            USER_INACTIVE_IN_COMPANY_DESC: 'Lo sentimos, actualmente el usuario se encuentra INACTIVO. Comuníquese con el administrador de la aplicación.',
            COMPANY_INACTIVE_DESC: 'Lo sentimos, actualmente el COMPAÑIA se encuentra INACTIVA. Comuníquese con el administrador de la aplicación.',
        },
        URL: {
            USER_HOME: INTERNAL_ROUTES.PANEL_USER_HOME,
            LOGIN: INTERNAL_ROUTES.AUTH_LOGIN,
            MAIN_LOGO_IMG: IMAGES_ROUTES.MAIN_LOGO_IMG,
            PAGE_404_IMG: IMAGES_ROUTES.PAGE_404_IMG,
            PAGE_401_IMG: IMAGES_ROUTES.PAGE_401_IMG,

        },
        BUTTON: {
            HOME: 'Inicio',
            BACK: 'Volver'
        },
    },
    BUTTON_EXPORT : {
        EXCEL: 'EXCEL',
        CSV: 'CSV',
        JSON: 'JSON',
        TEXT: 'TEXT',
    },
    ROLES : {
        ADMIN: '1',
        ADMIN_NAME: 'ADMIN',
        SUPERADMIN: '2',
        SUPERADMIN_NAME: 'SUPERADMIN',
    },
    TYPE_MATERIAL : {
        MP: 1,
        MEE: 2,
        IN: 3,
        ET: 4
    },
    MENU : {
        SUPERADMIN: 'Super Administrador',
    },
}
// Constantes para definir el color del Menu Izquierdo del sistema
export const LEFT_COLORS: ColorSwitcher[] = [
    { id: 0, value: "" },
    { id: 1, value: "1" },
    { id: 2, value: "2" },
    { id: 3, value: "3" },
    { id: 4, value: "4" },
    { id: 5, value: "5" },
    { id: 6, value: "6" },
    { id: 7, value: "7" },
    { id: 8, value: "8" },
];
// Constantes para definir el color del Menu Superior del sistema
export const HEADER_COLORS: ColorSwitcher[] = [
    { id: 0, value: "" },
    { id: 1, value: "#0727d7" },
    { id: 2, value: "#23282c" },
    { id: 3, value: "#e10a1f" },
    { id: 4, value: "#157d4c" },
    { id: 5, value: "#673ab7" },
    { id: 6, value: "#795548" },
    { id: 7, value: "#d3094e" },
    { id: 8, value: "#ff9800" },
];

export const VALUE_INSPECTION_MEE: ValueInspectionMEE[] = [
    { id: "SI", value: "SI" },
    { id: "NO", value: "NO" },
    { id: "NA", value: "NA" },
];

export const MESES:any[] =[
  {nombre:'Enero',numero:'1'},
  {nombre:'Febrero',numero:'2'},
  {nombre:'Marzo',numero:'3'},
  {nombre:'Abril',numero:'4'},
  {nombre:'Mayo',numero:'5'},
  {nombre:'Junio',numero:'6'},
  {nombre:'Julio',numero:'7'},
  {nombre:'Agosto',numero:'8'},
  {nombre:'Septiembre',numero:'9'},
  {nombre:'Octubre',numero:'10'},
  {nombre:'Noviembre',numero:'11'},
  {nombre:'Diciembre',numero:'12'},
];

export const DIAS_INVENTARIO_CAT:any[] = [
  {min:'0',max:'10',class:'bg-danger',color:'#dc3545'},
  {min:'11',max:'30',class:'bg-warning',color:'#ffc107'},
  {min:'31',max:'',class:'bg-success',color:'#198754'},
]
