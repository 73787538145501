<form [formGroup]="formAutocomplete" class="form-control">
    <input type="text" placeholder={{data.PLACEHOLDER.TITLE}}{{placeHolder}} matInput [formControl]="autoCompleteControl" formControlName="identifier"
        [matAutocomplete]="autoComplete">
    <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selectOption($event)">
        <div [ngSwitch]="typeAutoComplete">
            <div *ngSwitchCase="autoCompleteEnum.COMPANY">
                <mat-option *ngFor="let optionCompany of filteredOptions | async" [value]="optionCompany.companyName">
                    {{optionCompany.companyName}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.USERS">
                <mat-option *ngFor="let optionUser of filteredOptions | async" [value]="optionUser.username">
                    {{optionUser.firstName}} {{optionUser.lastName}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.PARENT_MODULES">
                <mat-option *ngFor="let optionParentModules of filteredOptions | async"
                    [value]="optionParentModules.modName">
                    {{optionParentModules.modName}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.COMPANIES_BY_USER">
                <mat-option *ngFor="let optionCompany of filteredOptions | async" [value]="optionCompany.companyName">
                    {{optionCompany.companyName}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.PROVIDERS">
                <mat-option *ngFor="let optionProvider of filteredOptions | async" [value]="optionProvider.proNombre">
                    {{optionProvider.proNombre}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.DEPARTMENTS">
                <mat-option *ngFor="let optionDepartments of filteredOptions | async"
                    [value]="optionDepartments.nombre">
                    {{optionDepartments.nombre}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.CITIES">
                <mat-option *ngFor="let optionCity of filteredOptions | async" [value]="optionCity.nombre">
                    {{optionCity.nombre}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.MATERIALS">
                <mat-option *ngFor="let optionMaterial of filteredOptions | async" [value]="optionMaterial.nomGenerico">
                    {{optionMaterial.nomGenerico}}
                </mat-option>
            </div>
            <div *ngSwitchCase="autoCompleteEnum.PARENT_MODULES_BY_COMPANY">
                <mat-option *ngFor="let optionParentModules of filteredOptions | async"
                    [value]="optionParentModules.modName">
                    {{optionParentModules.modName}}
                </mat-option>
            </div>
        </div>

    </mat-autocomplete>
</form>
