import { INTERNAL_ROUTES } from "@data/constants/routes";

export const QUALITY_CONSTANTS = {
    TITLES: {
        QUALITY: 'Calidad',
        QUALITY_ALL_MATERIALS: 'Ver Todos los Items',
        QUALITY_MATERIALS_TO_CHECK: 'Items por Revisar',
        QUALITY_MATERIALS_VIEW: 'Ver Material',
        QUALITY_INSPECTION_ALL_MATERIALS: 'Inspección Materiales',
        QUALITY_INSPECTION_MEE_NEW: 'Registrar Inspección MEE',
        QUALITY_INSPECTION_MP_NEW: 'Registrar Análisis MP',
        QUALITY_LIBERACION_AGUA: 'Liberación de agua',
        LIBERACION_AGUA_NEW: 'Nueva liberación',
        QUALITY_LIBERACION_AGUA_EDIT:'Actualizar Liberacion de Agua',
    },
    LIST: {
        SEARCH_MATERIALS: 'Buscar Materiales',
        APPROVE_MATERIAL_ORDER_TITLE: 'Aprobación Material',
        APPROVE_MATERIAL_ORDER_MSG: 'Se ha aprobado el material ',
        DECLINE_MATERIAL_ORDER_TITLE: 'Rechazo Material',
        DECLINE_MATERIAL_ORDER_MSG: 'Se ha rechazado el material ',
        PARTIAL_APPROVE_MATERIAL_ORDER_TITLE: 'Aprobado Parcial Material',
        PARTIAL_APPROVE_MATERIAL_ORDER_MSG: 'Se ha aprobado parcialmente el material ',
        SEARCH_MATERIALS_STATUS: 'Buscar por Estado',
        LIBERACION_AGUA_NEW: INTERNAL_ROUTES.KATIA_QUALITY_LIBERACION_AGUA_NEW,
        MSG_CONFIRM_INACTIVATE_LIBERACION: 'Inactivar liberacion de agua ##',
        UPDATE_LIBERACION_TITLE: 'Actualizar Liberacion de Agua',
        NEW_LIBERACION_TITLE: 'Registrar Liberacion de Agua',
        UPDATE_LIBERACION_DESC:'Liberacion de Agua Actualizada con Exito',
        NEW_LIBERACION_DESC:'Liberacion de Agua registrada con Exito'
    },
    APPROVE_DECLINE: {
        TITLE_QUALITY_AMOUNT:"Cantidad a rechazar",
        TITLE_QUALITY_NOTES: "Notas de Calidad",

    },
    VIEW: {
        MSG_GET_MATERIAL_TITLE: 'Información Material',
        MSG_GET_MATERIAL_DESC: 'No se encontro información para el Material Seleccionado',
    },
    INSPECTION_MEE: {
        TITLE: {
            CARACTERISTICAS: 'CARACTERÍSTICAS',
            PARAMETRO: 'PARÁMETRO',
            RESULTADO: 'RESULTADO',
            OBSERVACIONES: 'OBSERVACIONES',
        },
        NEW_EDIT: {
            MSG_MEE_NEW_TITLE: 'Registrar Inspección',
            MSG_MEE_NEW_DESC: 'Se Registro Exitosamente la inspección ',
        },
        ERROR: {
            INSPECCION_MEE_NEW_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar la inspección. Revise la información ingresada e intentelo nuevamente',
        },
        CARACTERISTICA: {
            ASPECTO_ENTEGA: 'ASPECTO DE ENTREGA',
            ASPECTO_MATERIAL: 'ASPECTO MATERIAL',
            TEXTO_COLOR: 'TEXTO Y COLOR',
            IMPRESION: 'IMPRESIÓN',
            TROQUELADO: 'TROQUELADO',
            PLASTIFICADO_BRILLO: 'PLASTIFICADO O BRILLO',
            DIMENSIONES: 'DIMENSIONES',
            PESO: 'PESO',
            FUNCIONALDAD: 'FUNCIONALDAD',
        },
        PAR_ASPECTO_ENTREGA: {
            ASPECTO_ADECUADO: '¿EL ASPECTO DEL EMBALAJE ES ADECUADO?',
            MATERIAL_IDENTIFICADO: '¿EL MATERIAL ESTA IDENTIFICADO?'
        },
        PAR_ASPECTO_MATERIAL: {
            MATERIAL_LIMPIO: '¿EL MATERIAL SE ENCUENTRA LIMPIO?',
            CUERPO_EXTRANIOS: '¿SE ENCUENTRAN CUERPOS EXTRAÑOS ADHERIDOS?',
            PERFORACIONES_FISURAS: '¿EXISTE PRESENCIA DE PERFORACIONES Y FISURAS?',
            QUEBRADURAS_RAYADURAS: '¿SE ENCUENTRAN QUEBRADURAS Y RAYADURAS?',
            ESQ_DOBLADAS_ARRUGADAS: '¿HAY ESQUINAS DOBLADAS O ARRUGADAS?',
            PEGUE_DESALINEADO: '¿EL PEGUE SE ENCUENTRA DESALINEADO?',
            CARENCIA_PEGUE: '¿HAY CARENCIA DE PEGUE?',
        },
        PAR_TEXTO_COLOR: {
            CORRECTO_RESPECTO_ARTE: '¿ESTA CORRECTO CON RESPECTO AL ARTE?',
            COLOR_LIMITE_ESTABLECIDO: '¿EL COLOR SE  ENCUENTRA DENTRO DE LOS LIMITES ESTABLECIDOS?'
        },
        PAR_IMPRESION: {
            CARENCIA_INCOMPLETA: 'CARENCIA O INCOMPLETA',
            SOBRE_IMPRESION: 'SOBRE IMPRESIÓN',
            DESGASTE_IMPRESION: '¿EXISTE DESGASTE DE LA IMPRESIÓN?',
            ADHERENCIA_TINTA: '¿HAY BUENA ADHERENCIA DE LA TINTA?',
            ILEGIBLE_BORROSA: '¿SE ENCUENTRA ILEGIBLE O BORROSA?',
        },
        PAR_TROQUELADO_BRILLO: {
            HAY_REBAJAS: '¿HAY REBABAS?',
        },
        PAR_PLASTIFICADO_BRILLO: {
            RESERVA_BRILLO: 'RESERVA DE BRILLO',
            BRILLO_UNIFORME: 'LACADO O BRILLO UNIFORME'
        },
        PAR_DIMENSIONES: {
            LARGO_ALTO: 'LARGO / ALTO',
            ANCHO: 'ANCHO',
            PROFUNDO: 'PROFUNDO',
            DIAMETRO: 'DIAMETRO',
            OTRAS: 'OTRAS',
        },
        PAR_PESO: {
            PESO: 'PESO',
        },
        PAR_FUNCIONALDAD: {
            CAPACIDAD: 'CAPACIDAD',
            ACOPLE_ENVASE: 'ACOPLE ENVASE-TAPA ENVASE-EMPAQUE'
        },
        VALIDACION: {
            SELECT_RESULT: 'Seleccione una Opción'
        }
    },
    INSPECTION_MP: {
        TITLE: {
            ENSAYO: 'ENSAYO',
            ESPECIFICACION: 'ESPECIFICACIÓN',
            RESULTADO: 'RESULTADO',
        },
        NEW_EDIT: {
            MSG_MP_NEW_TITLE: 'Registrar Inspeccion',
            MSG_MP_NEW_DESC: 'Se Registro Exitosamente la inspección ',
        },
        ERROR: {
            INSPECCION_MP_NEW_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar la inspección. Revise la información ingresada e intentelo nuevamente',
        },
        ENSAYO: {
            ASPECTO: 'ASPECTO',
            SOLUBILIDAD: 'SOLUBILIDAD',
            DENSIDAD: 'DENSIDAD',
            INDICE_REFRACCION: 'INDICE DE REFRACCIÓN',
        },
        CONCEPTO: 'CONCEPTO',
        OBSERVACIONES: 'OBSERVACIONES',
    },
    STATUS:{
      ACTIVE:1,
      INACTIVE:0,
      MSG_INACTIVATE:"Inactivar Liberacion de Agua",
    },
    NEW_EDIT:{
      BUTTON_BACK:"Atrás",
      KATIA_QUALITY_LIBERACION_AGUA_LIST:INTERNAL_ROUTES.KATIA_QUALITY_LIBERACION_AGUA_LIST,
    }
}
